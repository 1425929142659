<template>
  <collapse-card
    v-if="collaborationSectionState !== INVISIBLE"
    v-bind="$attrs"
    :model-value="true"
  >
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Collaboration
      </span>
    </template>
    <template #cardContent>
      <div
        v-if="collabTableLoading"
        class="flex items-center justify-center w-full py-28"
      >
        <base-svg
          class="h-4 w-4 mr-1 text-primary-red inline-block"
          src="icons/circleSpinner.svg"
          tag="span"
        />
        Loading ...
      </div>
      <div
        v-else
        class="py-2 px-4"
      >
        <div class="flex justify-between mb-3">
          <div class="flex">
            <icon-button
              class="mr-3 w-7.5 h-7.5"
              icon="icons/plus.svg"
              :disabled="submissionForm.assignUser !== userId"
              :active="isAddCollaborationBtnActive"
              active-btn-class="bg-custom-green-1 bg-opacity-20"
              active-icon-class="text-custom-green-1"
              type="button"
              @click="handleAddCollaboration()"
            />
            <icon-button
              class="w-7.5 h-7.5"
              icon="icons/x.svg"
              :active="isDeleteCollaborationBtnActive"
              active-btn-class="bg-primary-red bg-opacity-20"
              active-icon-class="text-primary-red"
              @click="handleEndCollaborations('delete')"
            />
          </div>
          <base-button
            variant="btn-primary"
            :disabled="!isEndCollaborationBtnActive"
            text="End Collaboration"
            type="button"
            @click="handleEndCollaborations('end')"
          />
        </div>
        <brand-assurance-table
          v-model="collaborationsTable"
          :are-columns-sortable="false"
          root-element-class="mb-10"
        >
          <!-- show 1 empty row if table is empty -->
          <template
            v-if="collaborationsTable.data.length === 0"
            #customTableBody
          >
            <tr>
              <td class="p-2 whitespace-nowrap text-center border border-r-0 border-b-0 last:border-r border-custom-gray-3 text-xs">
                &nbsp;
              </td>
              <td
                v-for="(_, tdIndex) in collaborationsTable.columns"
                :key="'cell' + tdIndex"
                class="p-2 whitespace-nowrap border border-r-0 border-b-0 last:border-r border-custom-gray-3 text-xs"
              >
                &nbsp;
              </td>
            </tr>
          </template>
          <!-- inject id link in collaboration ID column -->
          <template
            v-for="(collabIdCellSlotName, collabIndex) in collabIdCellSlotNames"
            :key="collabIdCellSlotName"
            #[collabIdCellSlotName]
          >
            <base-button
              class="ml-2"
              variant="btn-primary"
              :disabled="collaborationsTable.data[collabIndex].collaborators.find(name => name.userId === userId) || submissionForm.assignUser === userId ? false : true"
              :text="collaborationsTable.data[collabIndex].collaborationId"
              @click="openCollabDetails(collaborationsTable.data[collabIndex].collaborationId)"
            />
          </template>
          <!-- inject formatted date in started on column -->
          <template
            v-for="(startedOnCellSlotName, collabIndex) in startedOnCellSlotNames"
            :key="startedOnCellSlotName"
            #[startedOnCellSlotName]
          >
            {{ formatDate(collaborationsTable.data[collabIndex].startedDate) }}
          </template>
        </brand-assurance-table>

        <div
          v-if="hasOpenCollabs"
          class="text-primary-red"
        >
          Note: Please close all <span class="font-bold">Active</span> collaborations to continue with the submission process
        </div>
      </div>
    </template>
  </collapse-card>
  <!-- collaboration form modal -->
  <brand-assurance-collaboration-form-modal
    v-model="showCollaborationFormModal"
    @hide="closeCollaborationFormModal()"
    @collaborationUpdated="fetchCollaborations()"
  />
  <!-- confirm end collaboration modal -->
  <confirm-end-collaboration-modal
    v-model="showConfirmEndCollabModal"
    :status-mode="collabStatusChangeMode"
    @accepted="endCollaborations()"
    @rejected="setConfirmEndCollabModalVisibility(false)"
  />
</template>

<script>
import { computed, defineAsyncComponent, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import useSubmissionForm from './submissionForm.js';
import useCollaborationForm from '@/components/brand-assurance-collaboration-form/collaborationForm.js';
import { formatDate } from '@/helpers/util.js';
import useBaTable from '@/hooks/baTable.js';
import useUserAuth from '@/hooks/userAuth.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { CREATIVE_REVIEW_STEP, EDITABLE, INVISIBLE, LEGAL_REVIEW_STEP } from '@/constants/submissions.js';
import { COLLABORATION_PERMISSION } from '@/constants/permissions.js';
import { ERROR, USER_NOT_MASTER_COLLABORATOR, WARNING } from '@/constants/alerts.js';

export default {
    name: 'Collaborations',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue')),
        BrandAssuranceCollaborationFormModal: defineAsyncComponent(() => import('@/components/brand-assurance-collaboration-form/BrandAssuranceCollaborationFormModal.vue')),
        IconButton: defineAsyncComponent(() => import('@/components/IconButton.vue')),
        ConfirmEndCollaborationModal: defineAsyncComponent(() => import('@/components/ConfirmEndCollaborationModal.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    inheritAttrs: false,

    setup () {
        const store = useStore();
        const { generateTableSlotNamesByColumnKey } = useBaTable();
        const { submissionForm, submissionFormValidations } = useSubmissionForm();
        const { updateCollaborationForm } = useCollaborationForm();
        const { userPermissions } = useUserAuth();
        const { notificationsStack } = useToastNotifications();

        const proxyUser = computed(() => store.getters['auth/getUserProxyInfo']);
        const userId = computed(() => store.getters['auth/getUserId']);

        let userRolePermissions = [];

        if (proxyUser.value?.roles) {
            userRolePermissions = proxyUser.value?.permissions;
        } else {
            userRolePermissions = userPermissions.value;
        }


        // collaborations validation logic
        const collaborationSectionState = computed(() => {
            if (userRolePermissions.includes(COLLABORATION_PERMISSION) && [LEGAL_REVIEW_STEP, CREATIVE_REVIEW_STEP].includes(submissionForm.currentStepName)) {
                return EDITABLE;
            } else if (submissionForm.isAdminEdit && [LEGAL_REVIEW_STEP, CREATIVE_REVIEW_STEP].includes(submissionForm.currentStepName)) {
                return EDITABLE;
            } else {
                return INVISIBLE;
            }
        });

        // collaboration table logic
        const collaborationsTable = reactive({
            columns: [
                {
                    label: 'Collaboration ID',
                    key: 'collaborationId'
                },
                {
                    label: 'Collaboration Title',
                    key: 'collaborationTitle'
                },
                {
                    label: 'Master Collaborator',
                    key: 'collaborationMasterId'
                },
                {
                    label: 'Collaborators',
                    key: 'collaboratorsName'
                },
                {
                    label: 'Started On',
                    key: 'startedDate'
                },
                {
                    label: 'Status',
                    key: 'statusName'
                },
                {
                    label: 'Closed By',
                    key: 'closedBy'
                }
            ],
            data: []
        });
        const updateCollaborationTable = () => {
            collaborationsTable.data = fetchedCollaborationsList.value ? fetchedCollaborationsList.value.data : [];
            if (collaborationsTable.data.length) {
                collaborationsTable.data = collaborationsTable.data
                    .filter(c => c.status !== 3)
                    .map(collaboration => {
                        let statusName = 'Active';
                        switch (collaboration.status) {
                        case 2: statusName = 'Closed'; break;
                        case 3: statusName = 'Deleted'; break;
                        default: break;
                        }
                        return {
                            ...collaboration,
                            collaboratorsName: collaboration.collaborators?.length ? collaboration.collaborators.map(collaborator => collaborator.userName).join(', ') : '',
                            statusName
                        };
                    })
                    .sort((a, b) => {
                        if (!a.startedDate || !b.startedDate) return;
                        const t1 = new Date(a.startedDate);
                        const t2 = new Date(b.startedDate);
                        if (t1 < t2) return -1;
                        if (t1 > t2) return 1;
                        return 0;
                    });
            }
            if (collaborationsTable.data.length && collaborationsTable.data.some(c => c.status === 1)) submissionFormValidations.collaborations = 'Please close all Active collaborations to continue with the submission process';
            else submissionFormValidations.collaborations = '';
        };
        const selectedCollaborationsFromTable = computed(() => collaborationsTable.data?.length > 0 ? collaborationsTable.data.filter(collaboration => collaboration.selected) : []);

        const collabIdCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(collaborationsTable, 'collaborationId', 1));
        const startedOnCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(collaborationsTable, 'startedDate', 1));

        const collabTableLoading = ref(false);
        const fetchedCollaborationsList = computed(() => store.getters['baCollaborations/getCollaborationsList']);
        const fetchCollaborations = async () => {
            if (collabTableLoading.value) return;
            try {
                collabTableLoading.value = true;
                await store.dispatch('baCollaborations/fetchCollaborations', {
                    params: {
                        submissionId: submissionForm.submissionId
                    }
                });
                updateCollaborationTable();
            } catch (err) {
                console.error(err);
            } finally {
                collabTableLoading.value = false;
            }
        };

        onMounted(async () => {
            await fetchCollaborations();
        });

        // collaborations table action logic
        const isAddCollaborationBtnActive = computed(() => !collabTableLoading.value);
        const isDeleteCollaborationBtnActive = computed(() =>
            isAddCollaborationBtnActive.value &&
          selectedCollaborationsFromTable.value.length > 0 &&
          isCurrentUserMasterCollaborator.value
        );
        const isEndCollaborationBtnActive = computed(() =>
            isDeleteCollaborationBtnActive.value &&
          hasSomeOpenCollaborationsInSelection.value
        );

        const handleAddCollaboration = () => {
            try {
                collabTableLoading.value = true;
                updateCollaborationForm({
                    submissionId: submissionForm.submissionId,
                    status: 1,
                    submission: {
                        submissionId: submissionForm.submissionId,
                        shortArticleDescription: submissionForm.shortArticleDescription,
                        contractNumber: submissionForm.contractNumber,
                        styleGuideName: submissionForm.styleGuideName,
                        article: submissionForm.article,
                        currentStage: submissionForm.currentStage,
                        currentStep: submissionForm.currentStep,
                        status: submissionForm.status,
                        taskId: submissionForm.taskId,
                        assets: submissionForm.assets.map(a => {
                            delete a.submissionAssetKey;
                            return a;
                        })
                    }
                });
                setCollaborationFormModalVisibility(true);
            } finally {
                collabTableLoading.value = false;
            }
        };

        const isCurrentUserMasterCollaborator = computed(() => {
            return selectedCollaborationsFromTable.value && selectedCollaborationsFromTable.value.length > 0
                ? !selectedCollaborationsFromTable.value.some(collab => collab.collaborationMasterId !== userId.value) : false;
        });

        const hasSomeOpenCollaborationsInSelection = computed(() => {
            return selectedCollaborationsFromTable.value && selectedCollaborationsFromTable.value.length > 0
                ? selectedCollaborationsFromTable.value.some(collab => collab.status === 1) : false;
        });

        // collaboration form modal logic
        const showCollaborationFormModal = ref(false);
        const setCollaborationFormModalVisibility = (visibility) => {
            showCollaborationFormModal.value = visibility;
        };

        const fetchedCollabDetails = computed(() => store.getters['baCollaborations/getCollaborationsDetails']);
        const openCollabDetails = async (collabId) => {
            if (collabTableLoading.value) return;
            try {
                collabTableLoading.value = true;
                await store.dispatch('baCollaborations/fetchCollaborationDetails', { collabId });
                updateCollaborationForm(fetchedCollabDetails.value);
                setCollaborationFormModalVisibility(true);
            } catch (error) {
                notificationsStack.value.push({ type: ERROR, message: `Cannot open Collaboration ${collabId}` });
            } finally {
                collabTableLoading.value = false;
            }
        };

        const closeCollaborationFormModal = () => {
            setCollaborationFormModalVisibility(false);
        };

        // confirm end collaboration modal logic
        const showConfirmEndCollabModal = ref(false);
        const collabStatusChangeMode = ref('');
        const handleEndCollaborations = (mode) => {
            if (isCurrentUserMasterCollaborator.value) {
                setConfirmEndCollabModalVisibility(true, mode);
            } else {
                notificationsStack.value.push({
                    type: WARNING,
                    message: USER_NOT_MASTER_COLLABORATOR
                });
            }
        };
        const endCollaborations = async () => {
            try {
                const promiseArr = selectedCollaborationsFromTable.value
                    .filter(c => collabStatusChangeMode.value === 'delete' || c.statusName === 'Active')
                    .map(collab =>
                        store.dispatch('baCollaborations/closeCollaboration', {
                            bodyPayload: { status: collabStatusChangeMode.value === 'delete' ? 3 : 2 },
                            collabId: collab.collaborationId
                        }
                        ));
                await Promise.all(promiseArr);
                setConfirmEndCollabModalVisibility(false);
                await fetchCollaborations();
            } catch (err) {
                console.error(err);
            }
        };
        const setConfirmEndCollabModalVisibility = (visibility, mode) => {
            if (mode) collabStatusChangeMode.value = mode;
            else collabStatusChangeMode.value = '';

            showConfirmEndCollabModal.value = visibility;
        };

        const hasOpenCollabs = computed(() => collaborationsTable?.data?.length && collaborationsTable.data.some(c => c.status === 1));

        return {
            generateTableSlotNamesByColumnKey,
            formatDate,
            // collaborations validation
            INVISIBLE,
            collaborationSectionState,
            // collaboration table
            collaborationsTable,
            collabIdCellSlotNames,
            startedOnCellSlotNames,
            fetchCollaborations,
            // collaborations table action
            isAddCollaborationBtnActive,
            isDeleteCollaborationBtnActive,
            isEndCollaborationBtnActive,
            handleAddCollaboration,
            handleEndCollaborations,
            // collaboration form modal
            collabTableLoading,
            openCollabDetails,
            showCollaborationFormModal,
            closeCollaborationFormModal,
            // confirm end collaboration modal
            showConfirmEndCollabModal,
            endCollaborations,
            setConfirmEndCollabModalVisibility,
            hasOpenCollabs,
            collabStatusChangeMode,
            userId,
            submissionForm
        };
    }
};
</script>
